import * as React from 'react'

export const IntroContext = React.createContext({
  setShowCursor: (v: boolean) => {},
  showCursor: true,
  didShowIntro: false,
  setDidShowIntro: (v: boolean) => {},
  showIntro: true,
  setShowIntro: (v: boolean) => {},
  showLogo: false,
  setShowLogo: (v: boolean) => {},
  showNavLink: false,
  setShowNavLink: (v: boolean) => {},
  isAbout: false,
  setIsAbout: (v: boolean) => {}
})

export const useIntroContext = () => {
  return React.useContext(IntroContext)
}
