import { useStaticQuery, graphql } from 'gatsby'

export const getContent = () => {

  const { contentfulFrontpage, contentfulAbout } = useStaticQuery(graphql`
    query {
      contentfulFrontpage(contentful_id: { eq: "ED1x5EoCYdS8Qn8l0nUgz" }) {
        frontpageItems {
          projectName
          clientName
          landscapeTimestamp
          portraitTimestamp
        }
      }
      contentfulAbout(contentful_id: { eq: "1zszWPvL5W1mmzDlGyLB5z" }) {
        aboutText {
					aboutText
        }
        linneaText{
					raw
        }
        camillaText {
					raw
        }
        email
        office
        phone
        instagram
        vimeo
        collaborators {
          name
        }
        clients {
          logo {
            file {
							url
            }
          }
        }
      }
    }
  `)

  return {
    about: contentfulAbout,
    reelItems: contentfulFrontpage.frontpageItems
  }

}
