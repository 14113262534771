import * as React from 'react'
import { motion } from 'framer-motion'
import LogoSVG from '../svg/logo_2.svg'
import { Link } from 'gatsby'

interface LogoProps {
  className?: string,
  show: boolean,
  setShowCursor?: (v: boolean) => {},
  transition: {
    ease: string | number[],
    duration: number
  }
}

export const Logo = (props: LogoProps) => {

  const hiddenStyle = {
    y: props.show ? '0' : '105%'
  }

  const hiddenAnimate = {
    ...hiddenStyle,
    display: 'block',
    transition: {
      ...props.transition,
      delay: props.show ? 0.4 : 0
    }
  }

  const setShowCursor = (v: boolean) => {
    if (props.setShowCursor) props.setShowCursor(v)
  }

  return (
    <div
      className={props.className}
      onMouseEnter={() => setShowCursor(false)}
      onMouseLeave={() => setShowCursor(true)}
    >

      <Link to="/">

        <div className="logo__inner">

          <motion.h1
            initial={hiddenStyle}
            animate={hiddenAnimate}
          >
            <img src={LogoSVG} className="loader__logo" alt="Sundays" />
          </motion.h1>

        </div>

      </Link>

    </div>
  )

}
