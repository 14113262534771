import * as React from 'react'
import { Link } from 'gatsby'
import { useIntroContext } from './IntroContext'
import { motion } from 'framer-motion'

interface NavLinkProps {
  initial: any,
  animate: any
}

export const NavLink = (props: NavLinkProps) => {

  const { isAbout, setShowCursor } = useIntroContext()

  return (
    <div
      onMouseEnter={() => setShowCursor(false)}
      onMouseLeave={() => setShowCursor(true)}
    >

      {isAbout ? (
        <motion.nav initial={props.initial} animate={props.animate}>
          <Link to="/" className="nav-link">Work</Link>
        </motion.nav>
      ) : (
        <motion.nav initial={props.initial} animate={props.animate}>
          <Link to="/about" className="nav-link">About</Link>
        </motion.nav>
      )}

    </div>
  )

}