import * as React from 'react'
import { Layout } from './src/components/Layout'

export const wrapRootElement = (props) => {

  return (
    <Layout>
      {props.element}
    </Layout>
  )

}
