import 'scss/global.scss'
import * as React from 'react'
import { Logo } from './Logo'
import { IntroContext } from './IntroContext'
import { Helmet } from 'react-helmet'
import { getContent } from '../js/queries'
import { NavLink } from './NavLink'

export const Layout = props => {

  const [isAbout, setIsAbout] = React.useState(false)
  const [showLogo, setShowLogo] = React.useState(false)
  const [showIntro, setShowIntro] = React.useState(false)
  const [showNavLink, setShowNavLink] = React.useState(false)
  const [didShowIntro, setDidShowIntro] = React.useState(false)
  const [showCursor, setShowCursor] = React.useState(true)

  const { about } = getContent()

  const logoTransition = {
    ease: [0.25, 1, 0.5, 1],
    duration: 0.4
  }

  const headerStyle = {
    opacity: (isAbout || showNavLink) ? 1 : 0,
  }

  const headerAnimate = {
    ...headerStyle,
    transition: {
      ease: 'linear',
      delay: (isAbout || showNavLink) ? 0.4 : 0
    }
  }

  React.useEffect(() => {
    console.log(showCursor)
  }, [showCursor])

  React.useLayoutEffect(() => {

    const timer = setTimeout(() => {
      setShowNavLink(true)
    }, 2000)

    return () => {

      if (timer) {
        clearTimeout(timer)
      }

    }

  })

  return (
    <IntroContext.Provider value={{
      didShowIntro,
      setDidShowIntro,
      showIntro,
      setShowIntro,
      showLogo,
      setShowLogo,
      showNavLink,
      setShowNavLink,
      isAbout,
      setIsAbout,
      setShowCursor,
      showCursor
    }}>

      <main>

        <header>

          <Helmet>
            <meta charSet="utf-8" />
            <title>Sundays &ndash; We Make Films</title>
            <meta name="description" content={about.aboutText.aboutText} />
            <meta name="google-site-verification" content="dJ4lvWf5aQsmaroTcIGxEhVLRQFXJDgV3CivItmmCek" />
            <link rel="canonical" href="https://www.sundays.se" />
            <link rel="apple-touch-icon-precomposed" sizes="57x57" href="/apple-touch-icon-57x57.png" />
            <link rel="apple-touch-icon-precomposed" sizes="114x114" href="/apple-touch-icon-114x114.png" />
            <link rel="apple-touch-icon-precomposed" sizes="72x72" href="/apple-touch-icon-72x72.png" />
            <link rel="apple-touch-icon-precomposed" sizes="144x144" href="/apple-touch-icon-144x144.png" />
            <link rel="apple-touch-icon-precomposed" sizes="60x60" href="/apple-touch-icon-60x60.png" />
            <link rel="apple-touch-icon-precomposed" sizes="120x120" href="/apple-touch-icon-120x120.png" />
            <link rel="apple-touch-icon-precomposed" sizes="76x76" href="/apple-touch-icon-76x76.png" />
            <link rel="apple-touch-icon-precomposed" sizes="152x152" href="/apple-touch-icon-152x152.png" />
            <link rel="icon" type="image/png" href="/favicon-196x196.png" sizes="196x196" />
            <link rel="icon" type="image/png" href="/favicon-96x96.png" sizes="96x96" />
            <link rel="icon" type="image/png" href="/favicon-32x32.png" sizes="32x32" />
            <link rel="icon" type="image/png" href="/favicon-16x16.png" sizes="16x16" />
            <link rel="icon" type="image/png" href="/favicon-128.png" sizes="128x128" />
            <meta name="application-name" content="&nbsp;"/>
            <meta name="msapplication-TileColor" content="#FFFFFF" />
            <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
            <meta name="msapplication-square70x70logo" content="/mstile-70x70.png" />
            <meta name="msapplication-square150x150logo" content="/mstile-150x150.png" />
            <meta name="msapplication-wide310x150logo" content="/mstile-310x150.png" />
            <meta name="msapplication-square310x310logo" content="/mstile-310x310.png" />
          </Helmet>

          <NavLink initial={headerStyle} animate={headerAnimate} />

        </header>

        {props.children}

        <Logo
          show={(isAbout || showLogo)}
          className="logo"
          transition={logoTransition}
          setShowCursor={setShowCursor}
        />

      </main>

    </IntroContext.Provider>

  )

}
